import React from "react"
import { SteelunicornHeader } from "../components/steelunicorn-header"
import { SteelunicornProblem } from "../components/steelunicorn-problem"
import { SteelunicornHow } from "../components/steelunicorn-how"
import { SteelunicornWho } from "../components/steelunicorn-who"
import { SteelunicornFooter } from "../components/steelunicorn-footer"
import Helmet from "react-helmet"

export default () => {
  return (
    <div className="steelunicorn">
      <SteelunicornHeader/>
      <SteelunicornProblem/>
      <SteelunicornHow/>
      <SteelunicornWho/>
      <SteelunicornFooter/>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>SteelUnicorn</title>
      </Helmet>
    </div>
  )
}
