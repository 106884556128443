import React from "react"
import { ProblemCard } from "./problem-card"

export const SteelunicornProblem = () => {
  return (
    <div className="steelunicorn-problem steelunicorn-page">
      <div className="steelunicorn-problem-container">
        <div className="steelunicorn-problem-title steelunicorn-title">
          <h1>What problem do we solve?</h1>
          <p>Creating well-detailed drawings of reinforced concrete buildings is a laborious task,
            with a large number of similar operations that require a lot of time for highly skilled specialists.</p>
        </div>
        <div className="steelunicorn-problem-card-container">
          <ProblemCard title="Speed and cost"
                       description="Large penalties, it takes about 30% of the time and 20% of the budget of projects"/>
          <ProblemCard title="Competitiveness"
                       description="Struggle for projects, the market for construction companies is oversaturated, high cost of working time of highly qualified specialists"/>
          <ProblemCard title="Responsibility"
                       description="The lack of highly specialized personnel and the human error factor"/>
        </div>
      </div>
    </div>
  )
}