import React from "react"
import Button from '@material-ui/core/Button'

export const SteelunicornFooter = () => {
  return (
    <div className="steelunicorn-footer">
        <Button style={{ color: 'white', fontFamily: 'Playfair Display'}} href="http://84.201.167.81:8080/form">CiBlock project</Button>
      <p>welcome@steelunicorn.com</p>
    </div>
  )
}