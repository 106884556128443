import React from "react"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"

export const HowCard = (props) => {
  return (
    <div className="how-card">
      <h4><CheckCircleOutlineIcon fontSize="large" htmlColor="black"/>
        &nbsp;{props.title}</h4>
      <p>{props.description}</p>
    </div>)
}