import React from "react"
import { TeamCard } from "./team-card"
import mk from "../images/mk.png"
import es from "../images/es.png"
import fa from "../images/fa.png"
import fs from "../images/fs.png"


export const SteelunicornWho = () => {
  return (
    <div className="steelunicorn-who steelunicorn-page">
      <div className="steelunicorn-who-container">
        <div className="steelunicorn-who-title steelunicorn-title">
          <h1>Who we are?</h1>
          <p>Diversified specialists from different fields, united by a desire to make the design sphere better.</p>
        </div>
        <div className="steelunicorn-who-card-container">
          <TeamCard name="Mikhail Kraevskii" role="CEO" src={mk} alt="Mikhail"
                    abilities={["6 years of experience in civil and structural engineering", "3 years of AI-development", "Co-founder in 2 successful IT-startup"]}/>
          <TeamCard name="Elena Shishkina" role="CBDO" src={es} alt="Elena"
                    abilities={["4 launched IT-startups (already passed through break-even point)", "7 years of project and business processes management", "Ph.D. (Economics)", "Research (50 papers, h-index 4)"]}/>
          <TeamCard name="Fedor Amosov" role="CTO" src={fa} alt="Fedor"
                    abilities={["6 years in a senior position of AI development team", "Mentor in students startups projects", "Ph.D. (Mathematics)"]}/>
          <TeamCard name="Fedor Svetlov" role="Product Manager" src={fs} alt="Fedor"
                    abilities={["2 launched HoReCa business (sold)", "4 years of onlinemarketing (sales) and SMM management", "3 years of event management experience"]}/>
        </div>
      </div>
    </div>
  )
}